import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from "@angular/common";
import {I18nService} from "@biletix/core/services/i18n.service";
import { environment } from '@biletix/env';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';

@Pipe({
    name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

    environment = environment;
    constructor(private i18n: I18nService) {
    }

    /**
     *
     * @param value
     * @param count
     * @param currencyCode
     * @param display
     * @param digitsInfo
     */
    transform(value: number | string | any,
              quantity: number = -1,
              currencyCode: string | 'EUR' = environment.currencySymbolCode,
              display:
                  | 'code'
                  | 'symbol'
                  | string
                  | boolean = 'symbol',
              digitsInfo: string = environment.currencyDigit,
              locale: string = environment.currencyLocale): string | number | any {





        const symbol=getCurrencySymbol(currencyCode, 'wide', locale);
        let formatCurrencyValue = formatCurrency(
            this.formatTurkishLira(value),
            locale,
            symbol,
            currencyCode,
            digitsInfo,
        );

        if (quantity > -1) {
            if (this.i18n.currentLang == "en"){
                formatCurrencyValue = formatCurrencyValue + "x" + quantity;
            }else{
                formatCurrencyValue = "x" + quantity + formatCurrencyValue;
            }
        }

        return formatCurrencyValue;

    }


    private formatTurkishLira(value: number | string): number {
        if (value == null || value == '')
            return 0.0;

        return (+value / 100.0);
    }

}

import {Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {SeoService} from "@biletix/core/services/seo.service";
import {ApiService} from "@biletix/core/services/api.service";
import {ActivatedRoute,NavigationEnd, NavigationStart, Router} from "@angular/router";
import {I18nService} from "@biletix/core/services/i18n.service";
import {RegionService} from "@biletix/core/services/region.service";
import {TranslateService} from "@ngx-translate/core";
import {LocaleSelectorComponent} from "@biletix/core/common/locale-selector.component";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {Platform} from "@angular/cdk/platform";
import {ConsoleLogger} from "@biletix/core/logging/console.logger";
import {CartTimeoutService} from "@biletix/core/services/cart-timeout.service";
import {TicketSaleService} from "@biletix/core/services/ticket-sale.service";
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Locale } from './core/common';


// import { GoogleTagManagerService } from 'angular-google-tag-manager';

//declare const gtag: Function;


@Component({
    selector: 'btx-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends LocaleSelectorComponent implements OnInit {

    locales: Locale[];
    currentLocale: Locale;

    nl_pos = "bl";
    nl_link = "https://help.ticketmaster.co.il/hc/he/articles/20881962474897";
    nl_compact = "0";
    nl_accordion = "0";
    nl_dir = "assets/scripts/nl-files";
    nl_color = "blue";
    nl_lang = "en";


    constructor(private seo: SeoService,
                private i18n: I18nService,
                private translate: TranslateService,
                private apiService: ApiService,
                private renderer: Renderer2,
                private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                public platform: Platform,
                private consoleLogger: ConsoleLogger,
                private timeoutService: CartTimeoutService,
                private ticketSaleService: TicketSaleService,
                private router: Router,
                // private gtmService: GoogleTagManagerService,
                @Inject(PLATFORM_ID) private platformId: string,
                @Inject(DOCUMENT) private document: Document
    ) {
        super(translate, i18n);
        this.locales = this.i18n.getAvailableLocales();
        this.currentLocale = this.i18n.currentLocale;

      /*   this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              gtag('config', 'UA-27820024-1', { 'page_path': event.urlAfterRedirects });
            }
          }) */

        this.router.events.subscribe(e => {
                if (e instanceof NavigationStart) {
                  console.log('Navigation type: ', e);
                }
        });

    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        console.log('Back or forward button clicked', event);
        // Handle the popstate event here
    }

    /**
     *
     */
    ngOnInit(): void {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              console.log('Navigation ended, URL changed via pushState:', event.urlAfterRedirects,"---->",event);
            }
        });
        

        // if (this.currentLocale.lang === 'iw') {
        //     import('./iw-toaster.scss').then((module) => {
        //       const style = document.createElement('style');
        //       style.innerHTML = module.default;
        //       document.head.appendChild(style);
        //     });
        //   } else if (this.currentLocale.lang === 'en') {
        //     import('./en-toaster.scss').then((module) => {
        //       const style = document.createElement('style');
        //       style.innerHTML = module.default;
        //       document.head.appendChild(style);
        //     });
        //   }

        //Set
        if (this.platform.ANDROID)
            this.consoleLogger.log("Platform : ANDROID");


        //
//        this.initSeo();

        //
        this.addSvgIcons();

        // push GTM data layer for every visited page
        // this.router.events.forEach(item => {
        //     if (item instanceof NavigationEnd) {
        //     const gtmTag = {
        //         event: 'page',
        //         pageName: item.url
        //     };

        //     this.gtmService.pushTag(gtmTag);
        //     }
        // });

        if(this.platform.isBrowser){
            this.addNlScript();
        }
    }

    addNlScript(){


          const script = this.renderer.createElement('script');
            script.text = `
            window.nl_pos = "${this.nl_pos}";
            window.nl_link = "${this.nl_link}";
            window.nl_compact = "${this.nl_compact}";
            window.nl_accordion = "${this.nl_accordion}";
            window.nl_dir = "${this.nl_dir}";
            window.nl_color = "${this.nl_color}";
            window.nl_lang = "${this.i18n.currentLang}";
            `;

            // Body içerisine script elementini ekle
            this.renderer.appendChild(this.document.body, script);


            const nlScript = document.createElement('script');
          nlScript.type = 'text/javascript';
          nlScript.src = 'assets/scripts/nagishli.js';
          document.head.appendChild(nlScript);



    }
    /**
     *  Harici svg iconları mat-icon ile beraber kullanmak için harici svg iconları register eden metod.
     *
     *   @example:
     *    <mat-icon [svgIcon]="currentLocale.lang"></mat-icon>
     *    <mat-icon svgIcon="en"></mat-icon>
     *    <mat-icon [svgIcon]="'tr'"></mat-icon>
     */
    addSvgIcons() {

        // TODO: SVG olmalıdır.
        const internalSvgIcons: { iconName: string, iconUrl: string }[] = [
            {iconName: 'en', iconUrl: "assets/images/flag/en.svg"},
            {iconName: 'tr', iconUrl: "assets/images/flag/tr.svg"},
            {iconName: 'location', iconUrl: "assets/images/icon/location.svg"},
            {iconName: 'ticket', iconUrl: "assets/images/icon/ticket.svg"},
            {iconName: 'check_stroke', iconUrl: "assets/images/icon/check_stroke.svg"},
            {iconName: 'timer', iconUrl: "assets/images/icon/bg-orange-timer.svg"},
            {iconName: 'refresh', iconUrl: "assets/images/icon/refresh.svg"},
            {iconName: 'gray_right_chevron', iconUrl: "assets/images/icon/right-chevron.svg"},
        //    Social media icon
            {iconName: 'copy_link', iconUrl: "assets/images/icon/link.svg"},
            {iconName: 'email', iconUrl: "assets/images/icon/email.svg"},
            {iconName: 'messenger', iconUrl: "assets/images/icon/messenger.svg"},
            {iconName: 'messenger_circle', iconUrl: "assets/images/icon/messenger_circle.svg"},
            {iconName: 'sms', iconUrl: "assets/images/icon/sms.svg"},
            {iconName: 'whats_app', iconUrl: "assets/images/icon/whatsApp.svg"},
        ];

        //
        const domain = (isPlatformServer(this.platformId)) ? 'https://www.biletix.com/' : '';
        //this._matIconRegistry.addSvgIcon('loader', this._domSanitizer.bypassSecurityTrustResourceUrl(domain + svgU
        internalSvgIcons.forEach(item => {
                this.matIconRegistry.addSvgIcon(
                    item.iconName,
                    this.domSanitizer.bypassSecurityTrustResourceUrl(domain+item.iconUrl)
                );
            }
        );

    }

    // initSeo() {
    //     //TODO:Dil desteği verilecek
    //     this.seo.setTags({
    //         title: 'Biletix Eglenceye giris biletiniz... ', // Title
    //         titleSuffix: '', // Title Suffix
    //         description: 'Biletix - Eglenceye giris biletiniz... Biletinizi tek tusla, tek telefonla ve tek adimla alabilirsiniz. Çagri merkezimiz 0850 755 55 55', // Description
    //         image: `favicon.ico`, // Image
    //         keywords: 'biletix, bilet, resmi, spor, maç, müzik, konser, eğlence, tiyatro, festival, sahne sanatları, aile eğlencesi, satışta, eğitim, haftasonu, müze bileti ' // Keywords
    //     });
    // }
}

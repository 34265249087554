import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {RouteParameter} from "@biletix/core/common/constants/route-parameter.const";
import {I18nService} from "@biletix/core/services/i18n.service";
import {LocalStorageService} from "@biletix/core/services/local-storage.service";
import {RegionService} from "@biletix/core/services/region.service";
import {Location} from "@angular/common";
import { StatusCodeResponseService } from '../services/status-code-response.service';

/**
 * Url üzerinden dil seçeneği değiştirildiğinde veya url refresh olduğunda kontrol edilir.
 */
@Injectable()
export class RegionGuard implements CanActivate {

  constructor(private regionService: RegionService,
              private localStorage: LocalStorageService,
              private location:Location,
              private router: Router,
            private statusCodeResponseService:StatusCodeResponseService,) {
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //console.log("Region guard started");  
    let regionName = route.params[RouteParameter.PARAMS.REGION];

    regionName=regionName.toUpperCase();
    
    let region=this.regionService.getRegionByName(regionName);

    if (region) {
      if (this.regionService.currentRegion?.name!=regionName){
        this.regionService.use(region);
      }
    } else {
      //TODO: Bilinmeyen bir bölge seçildiğnde homepageya dönülecek. Veya istenilse önceki route geri döndürelebilir.
      // this.router.navigate(['/']);
       //this.location.back();
       this.statusCodeResponseService.setNotFound("Region not found");
    }
    //console.log("Region guard ended");
    return true;
  }

}

import {ShoppingStateItem} from "@biletix/core/models/state/shopping-state-item.model";
import {UpsellStateItem} from "@biletix/core/models/state/upsell-state-item.model";
import {StateItem} from "@biletix/core/models/state/state-item.model";
import {environment} from "@biletix/env";

import {PerformancePageStateService} from "@biletix/features/performance/services/performance-page-state.service";
import {ConcessionInfo} from "@biletix/core/models/event/concession-info.model";


/**
 * Temporary Shopping cart model
 *
 * TODO: Bu modele ihtiyaç dahilinde eklemeler yapılabiir.
 */
export class ShoppingCartState {

    shoppingStateItems: ShoppingStateItem[] = [];

    upsellStateItems: UpsellStateItem[] = [];

    digitalTicketCost:number=350; //get from service and change it

    addDigitalTicketCost:boolean=false;

    ticketProtectionSelected : boolean = false;

    constructor(option?: ShoppingCartState) {
        if (option) {
            this.shoppingStateItems = option.shoppingStateItems;
            this.upsellStateItems = option.upsellStateItems;
            this.addDigitalTicketCost=option.addDigitalTicketCost;
            this.digitalTicketCost=option.digitalTicketCost;
        }
    }

    public get totalPrice(): number {
        let totalPrice = this.calculateTotalPrice(this.shoppingStateItems) + this.calculateTotalPrice(this.upsellStateItems);
        if(this.addDigitalTicketCost && !this.ticketProtectionSelected){
            totalPrice+=this.digitalTicketCost;
        }
        if(this.ticketProtectionSelected){
            totalPrice+=this.ticketProtectionPremium;
        }
        return totalPrice ?? 0;
    }

    public get totalPriceForTicketProtection(): number {
        let totalPrice = this.calculateTotalPrice(this.shoppingStateItems);

        return totalPrice ?? 0;
    }



    private calculateTotalPrice(stateItems: StateItem[]) {
        return stateItems?.reduce((prev, cur) => prev + (cur.totalPrice??0), 0);
    }

    get ticketProtectionPremium(): number {
        let totalPrice = 0;
        totalPrice += this.totalPriceForTicketProtection ;

        return totalPrice;
    }

    public isEmpty(): boolean {
        return (this.shoppingStateItems?.length == 0 && this.upsellStateItems?.length == 0);
    }

    public isNotEmpty(): boolean {
        return (this.isNotEmptyUpsellItems || this.isNotEmptyShoppingItems);
    }

    public get ticketCount(): number {
        return this.totalCount(this.shoppingStateItems);
    }

    public get upsellCount(): number {
        return this.totalCount(this.upsellStateItems);
    }

    private totalCount(stateItems: StateItem[]) {
        return stateItems?.reduce((acc, cur) => acc + (cur.quantity), 0);
    }

    public get isNotEmptyShoppingItems() {
        return this.shoppingStateItems && this.shoppingStateItems.length > 0;
    }

    public get isNotEmptyUpsellItems() {
        return this.upsellStateItems && this.upsellStateItems.length > 0;
    }

    getShoppingStateItemByItemId(itemId: number): ShoppingCartState | any {
        if (this.shoppingStateItems && this.shoppingStateItems.length > 0) {
            return this.shoppingStateItems.find(item => item.itemId === itemId);
        }
        return null;
    }

    getShoppingStateItem(blockCode:string, row?:string, seat?:string) {
      return this.shoppingStateItems.find(item =>
        item.blockCode === blockCode
        && item.row === row
        && item.seat == seat);
    }

  getShoppingStateItemConcessionAndPriceInfo(concession:string, priceName:string, profileName:string, blockCode:string) {
    return this.shoppingStateItems.filter(item =>
      item.concessionName== concession &&
      item.categoryName == priceName &&
      item.profile.profileName == profileName &&
      item.blockCode == blockCode &&
      item.row == undefined &&
      item.seat == undefined);
  }

    addShoppingItem(shoppingStateItem: ShoppingStateItem) {
        if (shoppingStateItem) {
            this.shoppingStateItems.push(shoppingStateItem);
        }
    }

    removeShoppingItem(shoppingStateItem: ShoppingStateItem) {
        if (this.shoppingStateItems && this.shoppingStateItems.length > 0) {
            let index: number = this.shoppingStateItems.indexOf(shoppingStateItem);
            if (index >= 0) {
                this.shoppingStateItems.splice(index, 1);
            }
        }
    }

    getUpsellStateItemByItemId(itemId: number): UpsellStateItem | any {
        if (this.upsellStateItems && this.upsellStateItems.length > 0) {
            return this.upsellStateItems.find(item => item.itemId === itemId);
        }
        return null;
    }

    addUpsellStateItem(upsellStateItem: UpsellStateItem) {
        if (upsellStateItem) {
            this.upsellStateItems.push(upsellStateItem);
        }
    }

    removeUpsellStateItem(itemId: number) {
        if (this.upsellStateItems && this.upsellStateItems.length > 0) {
            let index: number = +this.upsellStateItems.findIndex(item => item.itemId === itemId);
            if (index >= 0) {
                this.upsellStateItems.splice(index, 1);
            }
        }
    }



    public setDigitalTicketCost(addDigitalTicketCost:boolean,digitalTicketCost:number){
        this.addDigitalTicketCost=addDigitalTicketCost;
        this.digitalTicketCost=digitalTicketCost;
    }

    public setTicketProtectionSelection(ticketProtectionSelected:boolean){
        this.ticketProtectionSelected = ticketProtectionSelected;
    }
}
